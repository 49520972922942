<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'operationLog',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/log/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '操作系统',
          type: 'input',
          key: 'os' /* records[0].browser */, // TODO
          cols: 8
        },
        {
          name: '操作IP地址',
          type: 'input',
          key: 'remoteAddr',
          cols: 8
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-90',
          isId: true,
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'os',
          title: '操作系统',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.os.length - b.os.length
        },
        {
          dataIndex: 'remoteAddr',
          title: '操纵IP地址',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.remoteAddr.length - b.remoteAddr.length
        },
        {
          dataIndex: 'method',
          title: '操纵方式',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.method.length - b.method.length
        },
        {
          dataIndex: 'methodName',
          title: '方法名',
          filter: true,
          type: 'lt-100',
          isId: true,
          sorter: (a, b) => a.methodName.length - b.methodName.length
        },
        {
          dataIndex: 'params',
          title: '提交数据',
          filter: true,
          isId: true,
          type: 'lt-100',
          sorter: (a, b) => a.params.length - b.params.length
        },
        {
          dataIndex: 'platform',
          title: '平台',
          filter: true,
          isId: true,
          type: 'lt-100',
          sorter: (a, b) => a.platform.length - b.platform.length
        },
        {
          dataIndex: 'remoteRegion',
          title: '远程地址',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.remoteRegion.length - b.remoteRegion.length
        },
        {
          dataIndex: 'requestUri',
          title: '请求uri',
          filter: true,
          isId: true,
          type: 'lt-100',
          sorter: (a, b) => a.requestUri.length - b.requestUri.length
        },
        {
          dataIndex: 'serviceId',
          title: '应用标识',
          filter: true,
          isId: true,
          type: 'lt-100',
          sorter: (a, b) => a.serviceId.length - b.serviceId.length
        },
        {
          dataIndex: 'time',
          title: '方法执行时间',
          filter: true,
          isId: true,
          type: 'lt-100',
          sorter: (a, b) => a.time.length - b.time.length
        },
        {
          dataIndex: 'title',
          title: '日志标题',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.title.length - b.title.length
        },
        {
          dataIndex: 'type',
          title: '日志类型',
          filter: true,
          isId: true,
          type: 'lt-100',
          sorter: (a, b) => a.type.length - b.type.length
        },
        {
          dataIndex: 'userAgent',
          title: '用户代理',
          filter: true,
          isId: true,
          type: 'lt-100',
          sorter: (a, b) => a.userAgent.length - b.userAgent.length
        },
        {
          dataIndex: 'updateTime',
          title: '操作时间',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => moment(a.updateTime).unix() - moment(b.updateTime).unix()
        },
        {
          dataIndex: 'delFlag',
          title: '删除标记',
          width: '60px',
          isId: true,
          type: 'badge',
          onExport: records => {
            return ['已删除', '正常'][records]
          },
          filters: [
            {
              text: '正常',
              value: '0'
            },
            {
              text: '已删除',
              value: '1'
            }
          ],
          onFilter: (value, record) => record.delFlag == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '正常' : '删除',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '详情',
                onClick: () => this.$router.push(`/monitoring/operationLogDetail?id=${records.id}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/log/${records.id}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return []
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
